<template>
  <b-modal
    id="PaymentModal"
    :visible="modalVisibility(modalId)"
    :title="`Payment Detail`"
    @show="onShow()"
    @hidden="resetModal()"
    @ok="onOk"
    size="lg"
    ok-only
    no-close-on-backdrop
    ok-title="Done"
  >
    <!-- Pending Bank Receipt -->
    <div v-if="selectedInvoice && selectedInvoice.payment_channel_id == 2">
      <b-row v-if="!!tableSettings">
        <b-col cols="3">
          <b-form-group class="m-2" label-size="sm">
            <template v-if="tableSettings.refresh">
              <b-button size="sm" class="mr-2">
                <i class="fa fa-sync"></i> Refresh
              </b-button>
            </template>
          </b-form-group>
        </b-col>
        <b-col cols="9">
          <b-form-group
            class="m-2"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
          >
            <template v-if="tableSettings.filter">
              <b-input-group size="sm">
                <b-form-input
                  v-model="bankReceipt.filter"
                  type="search"
                  placeholder="Type to Search"
                ></b-form-input>

                <b-input-group-append>
                  <b-button
                    :disabled="!bankReceipt.filter"
                    @click="bankReceipt.filter = ''"
                    >Clear</b-button
                  >
                </b-input-group-append>
              </b-input-group>
            </template>
          </b-form-group>
        </b-col>
      </b-row>

      <b-table
        :per-page="tableSettings.perPage"
        :current-page="tableSettings.currentPage"
        :filter="bankReceipt.filter"
        striped
        bordered
        show-empty
        hover
        :busy="isLoading"
        :items="BankReceipt"
        :fields="bankReceipt.fields"
      >
        <template #cell(approve_status_id)="row">
          <span
            :class="{
              'text-success': row.item.approve_status_id == 1,
              'text-warning': row.item.approve_status_id == 2,
              'text-danger': row.item.approve_status_id == 3,
            }"
          >
            {{ parseReceiptStatus(row.value) }}
          </span>
        </template>

        <template #cell(actions)="row">
          <b-button-group size="sm" class="w-100">
            <b-button @click="row.toggleDetails" variant="primary">
              <i
                :class="
                  `fa fa-${row.detailsShowing ? 'angle-up' : 'angle-down'}`
                "
                class="pr-0"
              ></i>
            </b-button>
            <b-button
              @click="
                onDownloadReceipt(
                  row.item.bank_receipt_id,
                  row.item.filename,
                  row.item.company_id
                )
              "
              variant="primary"
            >
              <i class="fa fa-file-download pr-0"></i>
            </b-button>
          </b-button-group>
        </template>

        <template #row-details="row">
          <!-- Reference -->
          <KTCard>
            <template #body>
              <b-row align-h="center">
                <b-col cols="4">
                  <strong>
                    Reference
                  </strong>
                </b-col>
                <b-col>
                  {{ row.item.reference || "N/A" }}
                </b-col>
              </b-row>
            </template>
          </KTCard>
          <!-- Reference -->

          <!-- Approve Form -->
          <KTCard v-if="row.item.approveForm">
            <template #body>
              <b-row align-h="center">
                <b-col cols="4">
                  <strong>
                    Approve Amount (MYR)
                  </strong>
                </b-col>
                <b-col>
                  <b-form-group>
                    <vue-numeric
                      separator=","
                      :precision="2"
                      class="form-control"
                      v-model="row.item.approveAmount"
                    >
                    </vue-numeric>
                  </b-form-group>
                </b-col>
              </b-row>
            </template>
          </KTCard>
          <!-- Approve Form -->

          <!-- Reject Form -->
          <KTCard v-if="row.item.rejectForm">
            <template #body>
              <!-- Alert -->
              <b-alert v-if="row.item.alerts.length" show variant="danger">
                <div v-for="(alert, index) in row.item.alerts" :key="index">
                  <i class="fa fa-fw fa-exclamation-circle"></i>
                  <strong>{{ alert }}</strong>
                </div>
              </b-alert>
              <!-- END Alert -->

              <b-row align-h="center">
                <b-col cols="4">
                  <strong>
                    Reject
                  </strong>
                </b-col>
                <b-col>
                  <b-form-textarea
                    v-model="row.item.rejectReason"
                    placeholder="Enter your reason"
                    @keyup="
                      onKeyUp(
                        getReceiptIndex(BankReceipt, row.item.bank_receipt_id)
                      )
                    "
                  ></b-form-textarea>
                </b-col>
              </b-row>
            </template>
          </KTCard>
          <!-- Reject Form -->

          <div v-if="row.item.approve_status_id == 2" class="text-right">
            <b-button
              v-if="row.item.approveForm || row.item.rejectForm"
              @click="
                row.item.approveForm = false;
                row.item.rejectForm = false;
              "
              variant="secondary"
              size="sm"
              class="rounded-pill ml-3"
            >
              Cancel
            </b-button>
            <b-button
              v-if="!row.item.approveForm"
              @click="
                onReject(getReceiptIndex(BankReceipt, row.item.bank_receipt_id))
              "
              variant="danger"
              size="sm"
              class="rounded-pill ml-3"
            >
              Reject
            </b-button>
            <b-button
              v-if="!row.item.rejectForm"
              @click="
                onApprove(
                  getReceiptIndex(BankReceipt, row.item.bank_receipt_id)
                )
              "
              variant="primary"
              size="sm"
              class="rounded-pill ml-3"
            >
              Approve
            </b-button>
          </div>
        </template>
      </b-table>

      <b-pagination
        v-if="tableSettings.row > tableSettings.perPage"
        class="pl-0"
        v-model="bankReceipt.currentPage"
        :total-rows="tableSettings.rows"
        :per-page="tableSettings.perPage"
      ></b-pagination>

      <!-- Pending Bank Receipt: <br />
      <pre>{{ BankReceipt }}</pre> -->
    </div>
    <!-- Pending Bank Receipt -->

    <!-- Billplz -->
    <div v-if="selectedInvoice && selectedInvoice.payment_channel_id == 1">
      <b-row v-if="!!tableSettings">
        <b-col cols="3">
          <b-form-group class="m-2" label-size="sm">
            <template v-if="tableSettings.refresh">
              <b-button size="sm" class="mr-2">
                <i class="fa fa-sync"></i> Refresh
              </b-button>
            </template>
          </b-form-group>
        </b-col>
        <b-col cols="9">
          <b-form-group
            class="m-2"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
          >
            <template v-if="tableSettings.filter">
              <b-input-group size="sm">
                <b-form-input
                  v-model="billplz.filter"
                  type="search"
                  placeholder="Type to Search"
                ></b-form-input>

                <b-input-group-append>
                  <b-button
                    :disabled="!billplz.filter"
                    @click="billplz.filter = ''"
                    >Clear</b-button
                  >
                </b-input-group-append>
              </b-input-group>
            </template>
          </b-form-group>
        </b-col>
      </b-row>

      <b-table
        :per-page="tableSettings.perPage"
        :current-page="tableSettings.currentPage"
        :filter="billplz.filter"
        striped
        bordered
        show-empty
        hover
        :items="billPlzTableItems"
        :fields="billplz.fields"
      >
      </b-table>

      <b-pagination
        v-if="tableSettings.row > tableSettings.perPage"
        class="pl-0"
        v-model="billplz.currentPage"
        :total-rows="tableSettings.rows"
        :per-page="tableSettings.perPage"
      ></b-pagination>
    </div>
    <!-- Billplz -->
  </b-modal>
</template>

<script>
import { validationMixin } from "vuelidate";
import { SET_MODAL_STATE } from "@/core/services/store/modules/ui.module";
import {
  GA_GET_ALL_BANK_RECEIPT,
  GA_APPROVE_BANK_RECEIPT,
  GA_REJECT_BANK_RECEIPT,
  GA_DOWNLOAD_BANK_RECEIPT,
  GET_INVOICE_LIST,
} from "@/core/services/store/modules/globaladmin/billing.module";

import SystemHelper from "@/core/services/systemhelper.service";
// import { email, required, sameAs } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],
  props: {
    TemplateeMode: { type: Boolean, default: false },
  },
  data() {
    return {
      filter: "",
      currentPage: 1,
      billplz: {
        filter: null,
        fields: [
          { key: "bill_id" },
          { key: "billplz_unique_ref" },
          { key: "status" },
        ],
        currentPage: 1,
      },
      bankReceipt: {
        filter: null,
        fields: [
          { key: "bank_receipt_id", label: "Receipt ID", sortable: true },
          { key: "bank_name", label: "Bank Name", sortable: true },
          { key: "bank_account", label: "Bank Account", sortable: true },
          { key: "approve_amount", label: "Amount", sortable: true },
          { key: "approve_status_id", label: "Status", sortable: true },
          {
            key: "bank_receipt_datetime",
            label: "Receipt Date",
            sortable: true,
          },
          {
            key: "created_at",
            label: "Uploaded On",
            sortable: true,
          },
          {
            key: "actions",
            label: "",
            headerTitle: "",
            class: "text-right",
            thStyle: "width: 20px; min-width: 20px",
          },
        ],
        currentPage: 1,
        approveForm: false,
        rejectForm: false,
      },
      alerts: [],
      readOnly: true,
      modalId: "PaymentModal",
      isLoading: true,
      form: {},
      selectedInvoice: {
        bills: null,
      },
    };
  },
  validations: {
    form: {},
  },
  computed: {
    tableSettings() {
      const list = this.$parent.selectedBills;
      let perPage = 20;
      let listLength;
      if (Array.isArray(list) && list.length) {
        listLength = list.length;
      } else {
        listLength = 100;
      }
      return {
        row: listLength,
        perPage: perPage,
        pagination: true,
        refresh: false,
        filter: true,
      };
    },
    billPlzTableItems() {
      const list = this.selectedInvoice.bills;
      console.log(list);
      if (Array.isArray(list) && list.length) {
        return list;
      } else {
        return [];
      }
    },
    BankReceipt() {
      const list = JSON.parse(
        JSON.stringify(this.$store.state.globaladmin_billing.gaAllBankReceipt)
      );
      if (Array.isArray(list) && list.length) {
        list.forEach((l) => {
          l.approveForm = false;
          l.rejectForm = false;
          l.rejectReason = null;
          l.parsedRejectReason = null;
          l.alerts = [];
        });
        return list;
      } else {
        return [];
      }
    },
  },
  methods: {
    textCreateMode(mode) {
      if (mode) {
        return "Create";
      } else {
        return "Update";
      }
    },
    async onShow() {
      this.isLoading = true;
      // system constants
      await this.getSystemConstants();

      this.selectedInvoice = this.$parent.selectedInvoice;
      if (this.selectedInvoice.payment_channel_id == 2) {
        const payload = {
          ...this.defaultPayload,
          invoice_id: this.selectedInvoice.invoice_id,
        };
        console.log("Payload", payload);
        this.$store.dispatch(GA_GET_ALL_BANK_RECEIPT, payload).then(() => {
          this.isLoading = false;
        });
      }
    },
    onApprove(i) {
      if (!this.BankReceipt[i].approveForm) {
        this.BankReceipt[i].approveForm = true;
        this.BankReceipt[i].rejectForm = false;
      } else {
        let p = this.BankReceipt[i];
        console.log("Approve", this.BankReceipt[i]);

        if (p.approveAmount) {
          // reset alerts
          this.BankReceipt[i] = [];

          // submit approve
          const payload = {
            bank_receipt_id: p.bank_receipt_id,
            approve_amount: p.approveAmount,
          };
          SystemHelper.confirmationDialogHandler(
            { title: "APPROVE BANK RECEIPT" },
            { action: GA_APPROVE_BANK_RECEIPT, payload: payload },
            [
              {
                action: GET_INVOICE_LIST,
                payload: {
                  ...this.defaultPayload,
                  company_id: parseInt(this.$route.query.id),
                },
              },
            ],
            this.modalId
          );
        } else {
          this.BankReceipt[i].push("Approve Amount cannot be empty");
        }
      }
    },
    onReject(i) {
      if (!this.BankReceipt[i].rejectForm) {
        this.BankReceipt[i].approveForm = false;
        this.BankReceipt[i].rejectForm = true;
      } else {
        let p = this.BankReceipt[i];
        console.log("Reject", p);

        if (p.parsedRejectReason) {
          // reset alerts
          this.BankReceipt[i].alerts = [];

          // submit reject
          const payload = {
            bank_receipt_id: p.bank_receipt_id,
            reject_reason: p.parsedRejectReason,
          };
          SystemHelper.confirmationDialogHandler(
            { title: "REJECT BANK RECEIPT" },
            { action: GA_REJECT_BANK_RECEIPT, payload: payload },
            [
              {
                action: GET_INVOICE_LIST,
                payload: {
                  ...this.defaultPayload,
                  company_id: parseInt(this.$route.query.id),
                },
              },
            ],
            this.modalId
          );

          console.log("rejected");
        } else {
          this.BankReceipt[i].alerts.push("Reject Reason cannot be empty");
        }
      }
    },
    onDownloadReceipt(receiptId, filename, companyId) {
      const payload = {
        bank_receipt_id: receiptId,
        filename: filename,
        company_id: companyId,
      };
      this.$store.dispatch(GA_DOWNLOAD_BANK_RECEIPT, payload);
    },
    onOk(bvModalEvent) {
      console.log(bvModalEvent);
      bvModalEvent.preventDefault();

      this.$store.dispatch(SET_MODAL_STATE, null);
    },
    onKeyUp(i) {
      this.BankReceipt[i].parsedRejectReason = this.BankReceipt[
        i
      ].rejectReason.replace(/\n/g, "<br/>");
    },
    parseReceiptStatus(id) {
      let status = Object.keys(this.SystemConstants.approveStatus).find(
        (key) => this.SystemConstants.approveStatus[key] == id
      );
      return status.replace(/_/g, " ");
    },
    getReceiptIndex(receipts, id) {
      return receipts.findIndex((receipt) => receipt.bank_receipt_id == id);
    },
    resetModal() {
      this.$v.form.$reset();
      this.bankReceipt.rejectReasonField = [];
      this.$store.dispatch(SET_MODAL_STATE, null);
    },
  },
};
</script>
