<template>
  <b-modal
    id="InvoiceModal"
    :visible="modalVisibility(modalId)"
    :title="`Invoice Detail`"
    @show="onShow()"
    @hidden="resetModal()"
    @ok="onOk"
    size="lg"
    ok-only
    no-close-on-backdrop
    ok-title="Done"
  >
    <b-row v-if="!!tableSettings">
      <b-col cols="3">
        <b-form-group class="m-2" label-size="sm">
          <template v-if="tableSettings.refresh">
            <b-button size="sm" class="mr-2">
              <i class="fa fa-sync"></i> Refresh
            </b-button>
          </template>
        </b-form-group>
      </b-col>
      <b-col cols="9">
        <b-form-group
          class="m-2"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
        >
          <template v-if="tableSettings.filter">
            <b-input-group size="sm">
              <b-form-input
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              ></b-form-input>

              <b-input-group-append>
                <b-button
                  :disabled="!filter.billplz"
                  @click="filter.billplz = ''"
                  >Clear</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </template>
        </b-form-group>
      </b-col>
    </b-row>

    <b-table
      :per-page="tableSettings.perPage"
      :current-page="tableSettings.currentPage"
      :filter="filter.billplz"
      striped
      bordered
      show-empty
      hover
      :items="tableItems"
      :fields="fields"
    >
    </b-table>

    <b-pagination
      v-if="tableSettings.row > tableSettings.perPage"
      class="pl-0"
      v-model="currentPage"
      :total-rows="tableSettings.rows"
      :per-page="tableSettings.perPage"
    ></b-pagination>
  </b-modal>
</template>

<script>
import { validationMixin } from "vuelidate";
import { SET_MODAL_STATE } from "@/core/services/store/modules/ui.module";
// import { email, required, sameAs } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],
  components: {},
  props: {
    createMode: { type: Boolean, default: false },
  },
  data() {
    return {
      filter: "",
      currentPage: 1,
      fields: [
        { key: "course_name" },
        { key: "description" },
        { key: "category" },
        { key: "unit_course_price" },
        { key: "quantity" },
      ],
      readOnly: true,
      modalId: "InvoiceModal",
      isBusy: true,
      form: {},
    };
  },
  validations: {
    form: {},
  },
  computed: {
    tableSettings() {
      const list = this.$parent.selectedInvoice;
      let perPage = 20;
      let listLength;
      if (Array.isArray(list) && list.length) {
        listLength = list.length;
      } else {
        listLength = 100;
      }
      return {
        row: listLength,
        perPage: perPage,
        pagination: true,
        refresh: false,
        filter: true,
      };
    },
    tableItems() {
      const list = this.$parent.selectedInvoice.invoice_details;
      console.log(list);
      if (Array.isArray(list) && list.length) {
        return list;
      } else {
        return [];
      }
    },
  },
  methods: {
    textCreateMode(mode) {
      if (mode) {
        return "Create";
      } else {
        return "Update";
      }
    },
    onShow() {
      console.log("showing");
      this.form = this.$parent.selectedInvoice;
    },
    onOk(bvModalEvent) {
      console.log(bvModalEvent);
      bvModalEvent.preventDefault();

      this.$store.dispatch(SET_MODAL_STATE, null);
    },
    resetModal() {
      this.$v.form.$reset();
      this.$store.dispatch(SET_MODAL_STATE, null);
    },
  },
};
</script>
