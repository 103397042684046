<template>
  <div>
    <KTCard title="Invoice List">
      <template v-slot:body>
        <b-row v-if="!!tableSettings">
          <b-col cols="6">
            <b-form-group class="m-2" label-size="sm">
              <template v-if="tableSettings.refresh">
                <b-button size="sm" class="mr-2">
                  <i class="fa fa-sync"></i> Refresh
                </b-button>
              </template>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              class="m-2"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
            >
              <template v-if="tableSettings.filter">
                <b-input-group size="sm">
                  <b-form-input
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                  ></b-form-input>

                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''"
                      >Clear</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
              </template>
            </b-form-group>
          </b-col>
        </b-row>

        <b-table
          :per-page="tableSettings.perPage"
          :current-page="tableSettings.currentPage"
          :filter="filter"
          striped
          bordered
          show-empty
          hover
          :items="tableItems"
          :fields="fields"
        >
          <template v-slot:cell(discount)="row">
            <vue-numeric
              type="string"
              read-only
              :currency="globalCurrency"
              separator=","
              v-bind:precision="2"
              v-model="row.item.unit_course_price"
            ></vue-numeric>
          </template>
          <template v-slot:cell(fee)="row">
            <vue-numeric
              type="string"
              read-only
              :currency="globalCurrency"
              separator=","
              v-bind:precision="2"
              v-model="row.item.unit_course_price"
            ></vue-numeric>
          </template>
          <template v-slot:cell(tax)="row">
            <vue-numeric
              type="string"
              read-only
              :currency="globalCurrency"
              separator=","
              v-bind:precision="2"
              v-model="row.item.tax"
            ></vue-numeric>
          </template>
          <template v-slot:cell(total)="row">
            <vue-numeric
              type="string"
              read-only
              :currency="globalCurrency"
              separator=","
              v-bind:precision="2"
              v-model="row.item.total"
            ></vue-numeric>
          </template>
          <template v-slot:cell(actions)="row">
            <b-button-group size="sm">
              <b-button
                @click="onViewInvoiceDetail(row.item)"
                variant="primary"
                size="sm"
              >
                View Details
              </b-button>
              <b-button
                @click="onViewPayment(row.item)"
                variant="light"
                size="sm"
              >
                Payments
              </b-button>
              <!--              <b-button @click="onView(row.item)" variant="secondary" size="sm">-->
              <!--                ViewPDF-->
              <!--              </b-button>-->
              <b-button @click="onDownload(row.item)" variant="info" size="sm">
                Download PDF
              </b-button>
            </b-button-group>
          </template>
        </b-table>

        <b-pagination
          v-if="tableSettings.row > tableSettings.perPage"
          class="pl-0"
          v-model="currentPage"
          :total-rows="tableSettings.rows"
          :per-page="tableSettings.perPage"
        ></b-pagination>
      </template>
    </KTCard>

    <invoice-modal :create-mode="createMode"></invoice-modal>
    <payment-modal :create-mode="createMode"></payment-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/modules/breadcrumbs.module";

import InvoiceModal from "@/view/pages/globaladmin/invoice/modals/InvoiceModal";
import PaymentModal from "@/view/pages/globaladmin/invoice/modals/PaymentModal";
import {
  GET_INVOICE_LIST,
  GA_DOWNLOAD_INVOICE,
} from "@/core/services/store/modules/globaladmin/billing.module";
import VueNumeric from "vue-numeric";
// import SystemHelper from "@/core/services/systemhelper.service";

export default {
  components: { InvoiceModal, PaymentModal, VueNumeric },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Global Admin" },
      { title: "Company" },
      { title: "Invoices" },
    ]);

    console.log(this.$route.query);
    let payload = {
      ...this.defaultPayload,
      company_id: parseInt(this.$route.query.id),
    };
    this.$store.dispatch(GET_INVOICE_LIST, payload);
  },
  computed: {
    tableSettings() {
      const list = this.$store.state.globaladmin_billing.invoiceList;
      let perPage = 20;
      let listLength;
      if (Array.isArray(list) && list.length) {
        listLength = list.length;
      } else {
        listLength = 100;
      }
      return {
        row: listLength,
        perPage: perPage,
        pagination: true,
        refresh: false,
        filter: true,
      };
    },
    tableItems() {
      const list = this.$store.state.globaladmin_billing.invoiceList;
      console.log(list);
      if (Array.isArray(list) && list.length) {
        return list;
      } else {
        return [];
      }
    },
  },
  data() {
    return {
      createMode: false,
      selectedInvoice: null,
      selectedBills: null,
      filter: "",
      currentPage: 1,
      fields: [
        { key: "invoice_id" },
        { key: "invoice_status_id" },
        { key: "tax" },
        { key: "total" },
        { key: "fee" },
        { key: "discount" },
        { key: "actions", class: "text-right" },
      ],
    };
  },

  methods: {
    onView(invoice) {
      // assign selected trainee
      this.selectedInvoice = invoice;
    },
    onDownload(invoice) {
      // assign selected trainee
      this.selectedInvoice = invoice;

      // download invoice
      const payload = {
        invoice_id: this.selectedInvoice.invoice_id,
      };
      this.$store.dispatch(GA_DOWNLOAD_INVOICE, payload);
    },
    onViewInvoiceDetail(detail) {
      this.createMode = false;
      console.log(detail);
      this.selectedInvoice = detail;
      this.openModal("InvoiceModal");
    },
    onViewPayment(invoice) {
      this.createMode = false;
      console.log(invoice);
      this.selectedInvoice = invoice;
      this.openModal("PaymentModal");
    },
  },
};
</script>
